:root {
  --brend-color: #ffa500;
  --light-blue-color: #f4f6fc;
  --dark-blue-color: #0b41cd;
  --green-color: #4e8351;
  --light-green-color: #f8fffd;
  --light-orange-color: #fff2ee;
  --gray-text-color: #000;
  --font-family: "Montserrat", sans-serif;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/font/Montserrat.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Revive80Phatt";
  src: url("./assets/font/Revive80Phatt.ttf");
  font-display: swap;
}

@font-face {
  font-family: "DancingScript";
  src: url("./assets/font/DancingScript.ttf");
  font-display: swap;
}
@font-face {
  font-family: "Calligraphy";
  src: url("./assets/font/LucidaUnicodeCalligraphy.ttf");
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body,
.App,
#root {
  margin: 0;
  padding: 0;
  position: relative;
  overflow-x: hidden;
  height: 100%;
  font-family: var(--font-family);
  // background: #fff;
}

.App {
  opacity: 0;
  &.visible {
    animation: transitionIn 1s forwards;
  }
}

section {
  margin: 100px 0;
}

@keyframes transitionIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

* {
  box-sizing: border-box;
}

p {
  font-size: 18px;
}

.container-main {
  width: 1400px;
  margin: 0 auto;
  position: relative;
}

.slider {
  width: 2000px;
  margin: 0 auto;
  margin-top: 60px;
}

.title {
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 30px;
  text-transform: uppercase;
  img {
    width: 28px;
  }
}

/* Slider */
.arrow-slider {
  background-color: var(--light-blue-color);
  border-radius: 50%;
  height: 35px;
  width: 35px;
  display: flex;
  color: #000;
  margin: 10px;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 16px;
  cursor: pointer;
  aspect-ratio: 1 / 1;
}

.slider-dots {
  display: flex;
  gap: 8px;
  margin: 0 15px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: #bbb;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dot.active {
  background: var(--brend-color);
}

.subtitle {
  font-size: 25px;
  font-weight: 300;
}

footer {
  margin-top: auto;
}

.thanks {
  text-align: center;
  margin-top: 80px;
}

@media (max-width: 1000px) {
  .title {
    font-size: 25px;
    display: flex;
    flex-direction: column;
  }
  p {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .container-main,
  #App {
    width: 95%;
  }
}

@media (max-width: 2050px) {
  .slider {
    width: 100%;
  }
}

.policy-logo img {
  width: 100%;
}

@media (max-width: 1130px) {
  .privacy-policy {
    padding: 0 20px;
  }
  iframe {
    width: 100%;
  }
}

.video-yt {
  width: 900px;
  height: 520px;
  @media (max-width: 850px) {
    width: 100%;
    height: 250px;
  }
}

.text-width {
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 850px) {
    width: 100%;
  }
}

.img-team {
  transition: transform 0.5s;
  float: left;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 430px) {
    float: none;
    margin-bottom: 20px;
  }
}

.sound-icon {
  position: absolute;
  right: 10px;
  top: -30px;
  cursor: pointer;
  background: var(--light-blue-color);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: var(--brend-color);
    font-size: 20px;
  }
  &:active {
    background: transparent;
  }
}

.change-lng-desktop {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  width: 100%;
  gap: 20px;
  padding-inline-start: 0;
}

.change-lng-desktop img {
  width: 40px;
}

.change-lng-desktop li {
  list-style: none;
  border: 1px solid #ccc;
  text-align: center;
  width: 150px;
  cursor: pointer;
  padding: 20px;
  border-radius: 30px;
}

.change-lng-desktop p {
  font-size: 13px;
  margin-bottom: 0;
}

@media (max-width: 910px) {
  .change-lng-desktop {
    display: grid;
    grid-template-columns: auto auto;
  }
}

.close-btn-lng {
  background: transparent;
  border: none;
  position: absolute;
  color: #000;
  font-size: 30px;
  top: 0;
  right: 0;
}

.services-icon-slider {
  background: var(--light-blue-color);
  border-radius: 30px;
  text-align: center;
  padding: 5px 20px;
  width: 290px;
  height: 310px;
}
.services-icon-slider img {
  width: 90px;
  transition: 0.5s;
}
.services-icon-slider h3 {
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
}
.services-icon-slider p {
  font-size: 13px;
}

.userway_buttons_wrapper {
  position: fixed !important;
  top: 84% !important;
}

//Form Newletter
.form-newsletter {
  background: var(--brend-color);
  padding: 100px 20px;

  section {
    margin: 0;
  }

  form {
    width: 500px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  input {
    width: 100%;
    padding: 13px;
    padding-right: 100px;
    border: none;
    border-radius: 30px;
    outline: none;
  }

  .button {
    background: var(--dark-blue-color);
    color: #fff;
    border-radius: 30px;
    position: absolute;
    border: none;
    right: 0;
    height: 100%;
    font-weight: 600;
  }

  p {
    font-size: 40px;
    color: #fff;
    text-align: center;
    padding: 0 100px;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    form {
      width: 100%;
    }
    p {
      font-size: 25px;
      padding: 0 0px;
    }
  }
}
