.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.loader img {
  width: 80px;
}
.logo-loader {
  font-size: 50px; /* Povećaj veličinu fonta po želji */
  font-weight: bold;
  margin-top: 200px;
  display: flex;
}

@media (max-width: 500px) {
  .logo-loader {
    font-size: 30px;
  }
  .loader img {
    width: 50px;
  }
}

.letter {
  position: relative; /* Omogućava pozicioniranje */
  color: transparent; /* Početna boja slova je transparentna */
  animation: fillColor 0.08s forwards; /* Animacija punjenja boje */
  animation-delay: calc(0.08s * var(--i)); /* Odloži svako slovo za 0.5s */
}

@keyframes fillColor {
  0% {
    color: transparent; /* Početna boja slova je transparentna */
  }
  100% {
    color: var(--brend-color); /* Krajnja boja slova */
  }
}
