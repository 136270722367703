.scrollTop {
  background: var(--brend-color);
  width: 40px;
  color: #fff;
  height: 40px;
  border-radius: 50%;
  position: fixed;
  z-index: 2;
  bottom: 10px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
}
