.news-box {
  position: relative;

  > div {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin-top: 50px;
  }

  a {
    background: var(--brend-color);
    color: #fff;
    padding: 10px 50px;
    border-radius: 100px;
    text-decoration: none;

    &:hover {
      color: var(--light-blue-color);
    }
  }

  p {
    margin: 30px 0;
  }

  div img {
    width: 400px;
  }
}

.line-news {
  height: 2px;
  background: #939393;
}

@media (max-width: 820px) {
  .news-box {
    > div {
      flex-direction: column;
    }

    div img {
      width: 100%;
    }
  }
}

.row-blog {
  display: flex;

  img {
    width: 40%;
    height: 800px;
    object-fit: cover;
  }
  .img-blog5 {
    width: 50%;
    height: 100%;
  }

  @media (max-width: 1000px) {
    display: block;

    img {
      width: 100%;
      margin-bottom: 40px;
    }
    .img-blog5 {
      margin-bottom: 0;
      width: 100%;
    }
  }
}
