.post {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
  margin-top: 80px;

  .post-img {
    width: 100%;

    img {
      width: 100%;
    }
  }
}

@media (max-width: 800px) {
  .post {
    flex-direction: column;
    gap: 0;

    div {
      margin-bottom: 0;
    }

    .post-img {
      margin: 30px 0;
      width: 100% !important;
    }
  }
}

.episodes {
  margin-top: 100px;
  display: grid;
  gap: 100px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

  div {
    width: 650px;

    @media (max-width: 700px) {
      width: 100%;

      iframe {
        height: 300px;
      }
    }
  }
}

.insulin-img {
  margin: 50px 0;

  img {
    width: 100%;
  }
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.blog-row {
  display: flex;

  img {
    width: 50%;
    object-fit: cover;
  }

  @media (max-width: 1100px) {
    flex-direction: column;

    img {
      width: 100%;
    }
  }
}
