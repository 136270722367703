.navbar-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  background: var(--brend-color);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: fixed;
  z-index: 999;
}

.logo img {
  width: 180px;
}
.logo-mobile-menu {
  margin: 0 30px 50px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo-mobile-menu img {
  width: 150px;
}

nav > ul {
  display: flex;
  padding-inline-start: 0;
  margin: 0;
}

nav ul li {
  list-style: none;
  position: relative;
}

nav ul li a,
nav ul li span {
  margin-left: 5px;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 20px;
  transition: .3s;
}

.menu nav ul {
  display: flex;
  align-items: center;
}

nav ul li a:hover,
nav ul li span:hover {
  color: var(--brend-color) !important;
  background: var(--light-blue-color);
}

.lng-con {
  display: flex;
  padding-inline-start: 0;
  align-items: center;
  margin-bottom: 0;
}
.lng-con li {
  list-style: none;
}

nav ul li a.active {
  color: var(--brend-color) !important;
  background: var(--light-blue-color);
}

nav ol li a.active {
  background: var(--brend-color);
  color: var(--light-blue-color) !important;
}

.about-us,
.com-ser {
  display: flex;
  align-items: center;
}

ul li ol.menu-down {
  display: none;
  width: max-content;
  position: absolute;
  left: 10px;
  background: var(--light-blue-color);
  z-index: 99999999;
  animation: menu_down 1s;
  padding-left: 0;
}

.menu-areas {
  position: absolute;
  background: var(--light-blue-color);
  width: 100%;
  right: -180px;
  top: 0;
}

.change-lng-mobile {
  padding-inline-start: 0;
}

.change-lng-mobile li {
  cursor: pointer;
}

@keyframes menu_down {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

ul li ol.menu-down li a,
ul li ol.menu-down li span {
  color: #000;
  margin: 0;
  display: flex;
  width: 100%;
}

.menu nav ol li a {
  text-transform: none;
}

ul li ol.menu-down li a:hover {
  background: var(--brend-color);
  color: var(--light-blue-color) !important;
}

.burger-icon {
  font-size: 40px;
  display: none;
  margin-left: 20px;
  cursor: pointer;
}
.line {
  background: #fff;
  height: 2px;
}
.line.home {
  background: #fff;
}
.line1 {
  width: 35px;
}

.line2 {
  width: 25px;
  margin: 6px 0;
}
.line3 {
  width: 15px;
}

@media (max-width: 1600px) {
  .navbar-box {
    width: 100%;
  }
}
@media (min-width: 1300px) {
  ul li:hover ol.menu-down {
    display: block !important;
    box-shadow: 0px 0px 29px -11px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
  }

  nav ul li a,
  nav ul li span {
    color: #fff;
  }
  nav ol li a,
  nav ol li span {
    color: #000;
  }

  .showMenu {
    display: none;
  }
  .close-menu {
    display: none;
  }
  .menu-background {
    display: none;
  }
  .logo-mobile-menu {
    display: none;
  }
  .lng-con {
    display: none;
  }
  .internet-mob {
    display: none;
  }
  .email-mobile {
    display: none;
  }
  .lng-change {
    display: none;
  }
}

@media (max-width: 1300px) {
  .menu nav ul {
    background: #fff;
    position: fixed;
    top: 0;
    right: -100%;
    width: 50%;
    height: 100%;
    display: block;
    margin: 0;
    padding: 30px 0 0 0;
    transition: .5s all ease-in-out;
    z-index: 99;
    overflow-x: auto;
  }
  .menu nav ul.active {
    right: 0;
    height: 100%;
    line-height: 30px;
  }

  .menu nav ul ol {
    padding-left: 15px;
    padding-right: 15px;
  }

  .menu nav ol li a {
    text-transform: none;
  }

  .logo img {
    width: 150px;
  }

  .menu-areas {
    position: relative;
    line-height: 30px;
    background: #fff;
    right: 0;
    padding-left: 30px;
  }

  ul li ol.active {
    line-height: 30px;
    display: block !important;
    animation: menu_down 1s;
  }

  @keyframes menu_down {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .internet-mob {
    margin: 100px 20px;
  }
  .menu-background {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    transition: .8s;
    z-index: 8;
    display: block;
  }
  .menu-background.active {
    width: 100%;
    transition: .8s;
  }

  .about-us,
  .com-ser {
    justify-content: space-between;
  }

  .menu nav ul a,
  .menu nav ul span {
    font-size: 18px;
    display: flex;
    color: #000;
  }

  .menu nav ul li {
    width: 100%;
    margin: 5px 0;
  }

  .burger-icon {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .close-menu {
    font-size: 30px;
    color: var(--gray-text-color);
    cursor: pointer;
  }

  .email-icon {
    font-size: 20px;
    color: #939393;
  }

  .email-mobile a {
    margin: 0 20px;
  }

  .lng-change {
    font-size: 20px;
    color: #939393;
    cursor: pointer;
    width: 60px;
  }
  .lng-change.home {
    color: #fff;
  }
}

@media (max-width: 1500px) {
  .logo-connects {
    display: none;
  }
}

@media (max-width: 790px) {
  .menu nav ul {
    width: 100%;
  }
}

.bayleyssvg {
  display: block;
  margin: 80px auto;
  width: 800px;
  margin-bottom: 15px;
}

#bayleys {
  fill: #ffffff;
  stroke: #ffffff;
}

#bayleys {
  stroke-width: 0;
  animation: logo 5s linear infinite;
}

@keyframes logo {
  0% {
    stroke-width: 1pt;
    stroke-dashoffset: 1200;
    fill-opacity: 0;
  }
  50% {
    fill-opacity: 0;
  }
  100% {
    stroke-dashoffset: 0;
    fill-opacity: 1;
    stroke-width: 0pt;
  }
}
