.admin-login,
.admin-panel {
  background: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  padding-top: 0px;

  .button {
    background: var(--dark-blue-color);
    border: none;
    border-radius: 30px;
    font-size: 16px;
    padding: 15px 100px;
    background: blue;
    color: #fff;
  }

  img {
    width: 200px;
  }
  h1 {
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  input {
    display: block;
    width: 500px;
    border-radius: 30px;
    border: 1px solid gray;
    padding: 15px;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  p {
    margin: 0 !important;
  }
}

.admin-panel {
  .logout {
    border: none;
    border-radius: 20px;
    font-size: 13px;
    padding: 5px 20px;
    background: blue;
    color: #fff;
  }

  table {
    width: 900px;
    margin-top: 50px;
    button {
      font-size: 13px;
      border: none;
      background: red;
      color: #fff;
      border-radius: 20px;
      padding: 5px 20px;
      margin-right: 10px;
    }
    .copy {
      background: gray;
    }
  }
}
