.box-img,
.slider-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  position: relative;
  margin-bottom: 50px;

  div {
    overflow: hidden;
    img {
      transition: .6s;
      &:hover {
        transform: scale(1.07);
      }
    }
  }

  :nth-child(1) {
    margin-left: 0;
  }

  :nth-child(3) {
    margin-right: 0;
  }
}

.carousel-caption {
  position: absolute;
  bottom: 0;
  color: var(--dark-blue-color);
  // background: rgb(244, 246, 252, 0.9);
  width: 100%;
  height: 80px;
  display: none;
  padding: 30px 20px;
  display: flex;
  bottom: 0 !important;
  left: 0 !important;
  align-items: center;
  justify-content: space-between;
  color: var(--dark-blue-color) !important;

  p {
    margin: 0;
    font-size: 25px;
    font-weight: 500 !important;
  }

  @media (max-width: 700px) {
    p {
      font-size: 20px;
      text-align: start;
    }
  }
}

@media (min-width: 1600px) {
  .box-img {
    > div {
      width: 100%;
      margin: 0 2px;
      position: relative;

      img {
        width: 100%;
        height: 500px;
        object-fit: cover;
      }
    }
  }
}

.text-icon {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;

  img {
    width: 200px;
  }

  @media (max-width: 690px) {
    display: block;
    text-align: center;

    p {
      margin-top: 30px;
    }

    img {
      width: 200px;
    }
  }
}

@media (max-width: 2000px) {
  .box-img {
    width: 100%;

    > div {
      width: 100%;
      position: relative;

      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 1300px) {
  .box-img {
    margin-top: 40px;
  }
}

@media (min-width: 1000px) {
  .slider-main {
    display: none;
  }
}
@media (max-width: 1000px) {
  .box-img {
    display: none !important;
  }
  .slider-main {
    img {
      height: auto;
    }
  }
}

.video-envi {
  text-align: center;
  margin: 50px 0;

  p {
    color: #0000ff;
  }

  @media (min-width: 1250px) {
    margin-top: 0 !important;
  }
}

// Values

.row-img-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 200px;
  padding: 20px 15px;
  margin-bottom: 70px;

  @media (max-width: 1000px) {
    gap: 50px;
  }

  @media (max-width: 1140px) {
    flex-direction: column;
  }

  .row-img {
    position: relative;
    img {
      width: 600px;

      @media (max-width: 1000px) {
        width: 100%;
      }
    }

    > div {
      position: absolute;
      right: -100px;

      div {
        width: max-content;
        padding: 10px;
        font-weight: bold;
        font-size: 30px;
        text-transform: uppercase;
        color: #fff;
        margin-bottom: 20px;
        text-align: center;
        background: var(--brend-color);
      }
    }
  }
}

.our-social {
  width: 100%;
  margin-bottom: 100px;
}
.our-social-mobile {
  width: 100%;
  margin-bottom: 100px;
}

@media (max-width: 600px) {
  .our-social-mobile {
    display: block;
  }

  .our-social {
    display: none;
  }
}

@media (min-width: 600px) {
  .our-social-mobile {
    display: none;
  }
}

.row2,
.row4 {
  flex-direction: row-reverse;

  @media (max-width: 1140px) {
    flex-direction: column !important;
  }

  .row-img {
    > div {
      left: -100px;
    }
  }
}

.row2,
.row4 {
  .row-img {
    > div {
      position: relative;
      div {
        position: absolute;
        left: 0px;
        bottom: 80px;

        @media (max-width: 567px) {
          left: 100px;
          bottom: -42px;
        }
      }
    }
  }
}

.row4 {
  flex-direction: row-reverse;
}

.rotatingText-adjective {
  position: absolute;
  right: 0;
  bottom: 80px;

  @media (max-width: 567px) {
    right: 100px;
    bottom: -42px;
  }
}

// Values end

.references {
  li {
    font-size: 13px;
  }

  p {
    font-size: 13px;
  }
}

ol {
  padding-inline-start: 0;
}

.box-pharma {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 40px;
  margin: 50px 0;

  div {
    width: 300px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    text-align: center;

    img {
      width: 200px;
      display: flex;
      transition: .8s;

      &:hover {
        width: 220px;
      }
    }
  }
}
