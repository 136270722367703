.cookies {
  width: 100%;
  padding: 20px;
  background: var(--light-blue-color);
  box-shadow: 0px 10px 30px 2px rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  bottom: -200%;
  font-size: 16px;
  z-index: 9;
  right: 0;
  transition: all 0.5s;

  @media (max-width: 1200px) {
    width: 100%;
  }

  p {
    font-size: 14px;
  }

  a {
    color: var(--dark-blue-color);
  }

  div {
    margin-top: 20px;
    display: flex;
    gap: 20px;

    :nth-child(1) {
      background-color: var(--brend-color);
      color: #fff;
      border: 1px solid var(--brend-color);
    }

    button {
      border: 1px solid var(--dark-blue-color);
      background: transparent;
      border-radius: 30px;
      color: var(--dark-blue-color);
      padding: 5px 20px;
      font-size: 12px;
      cursor: pointer;
      text-transform: capitalize;
    }
  }
}
.cookies.active {
  bottom: 0;
}
